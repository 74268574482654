.uploadBox {
  cursor: pointer;
  display: flex;
}

.importFileContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-start;
}

.importFileContainer img {
  border-radius: 5px;
}

.imgContainer {
  @apply
  flex justify-center items-center relative
}

span.error {
  @apply
  text-red
  mt-2
  text-[14px]
}

.imgContainer > .changeBtn {
  @apply
  opacity-50 bg-black py-1 px-3 rounded-lg absolute text-white
}