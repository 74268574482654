.container {
  @apply px-[40px]
  pt-[30px];
}

.container > span {
  @apply flex
  justify-center
  pt-[20px]
  text-[20px];
}
