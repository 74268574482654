.comming_soon_wrapper {
    @apply
    w-full
    px-[150px]
    sm:px-[70px]
    xs:px-[16px]
    h-full
    flex-grow
    flex
    flex-col
    items-center
    min-h-[80vh]
}

.comming_soon_wrapper .comming_soon {
    @apply
    w-full
    max-w-[1583px]
    h-full
    flex
    flex-col
    flex-grow
    items-center
    justify-center
}

.comming_soon_wrapper .comming_soon .title {
    @apply
    text-[40px]
    flex
    items-center
    justify-start
}