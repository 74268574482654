.header_wrapper {
    @apply
    w-full
    flex
    justify-center
    items-start
    pt-[30px]
    px-[40px]
    mobile:px-[16px]
    relative
    z-20
}
.header_wrapper.sticky {
    background: #171936;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.60);
    animation: sticky_animation 1s normal ease;
    @apply
    fixed
    overflow-hidden
    top-0
    left-0
    py-[10px]
}

@keyframes sticky_animation {
    from {
        top: -115px;
    }
    to {
        top: -2px;
    }
}

.header_wrapper.sticky::before {
    background: url("./../../pic/home/bg-noise.png");
    background-repeat: repeat;
    content: "";
    @apply
    w-full
    absolute
    left-0
    top-0
    h-full
}

.header_wrapper.fixed_class {
    position: fixed;
    left: 0;
    width: 100%;
}

.cover {
    background: linear-gradient(180deg, #151636 0%, rgba(0, 0, 0, 0.02) 57.22%);
    backdrop-filter: blur(10px);
    @apply
    fixed
    w-full
    h-full
    top-0
    left-0
    z-[9]
    duration-[0.5s]
    opacity-0
    invisible
}
.cover.active {
    @apply
    opacity-100
    visible
}
.header_wrapper .header {
    @apply
    w-full
    max-w-[1583px]
}

.header_wrapper .header .navbar {
    @apply
    w-full
    flex
    justify-between
    items-center
}
.header_wrapper .header .navbar .button_open_menu {
    @apply
    hidden
    xl:flex
    w-[24px]
    h-[24px]
    min-w-[24px]
    items-center
    justify-between
    flex-col
    cursor-pointer
    ml-auto
    z-[1]
}

.header_wrapper .header .navbar .button_open_menu .image {
    @apply
    w-[24px]
    h-[24px]
    flex
    items-center
    justify-center
    relative
    z-[1]
}

.header_wrapper .header .navbar .button_open_menu .image img {
    @apply
    max-w-full
    max-h-full
    object-contain
}

.header_wrapper .header .navbar .logo {
    @apply
    flex
    items-center
    justify-center
    relative
    z-[1]
}

.header_wrapper .header .navbar .logo .image {
    @apply
    w-[58px]
    mr-[15px]
    sm:w-[30px]
}

.header_wrapper .header .navbar .logo .image img {
    @apply
    max-w-full
    max-h-full
    object-contain
}

.header_wrapper .header .navbar .logo .text {
    @apply
    w-[83px]
    sm:w-[43px]
}
.header_wrapper .header .navbar .logo .text img {
    @apply
    max-w-full
    max-h-full
    object-contain
}
.header_wrapper .header .navbar .list_nav .nav_mobile_header {
    @apply
    w-full
    hidden
    items-center
    justify-between
    xl:mb-[80px]
    xl:flex
}
.header_wrapper .header .navbar .list_nav {
    box-shadow: 0 0 0 0;
    @apply
    relative
    flex
    items-center
    justify-between
    w-full
    xl:w-[288px]
    xl:h-screen
    xl:fixed
    xl:left-[-300px]
    xl:top-0
    xl:z-10
    xl:flex-col
    xl:items-start
    xl:justify-start
    xl:p-[20px]
    xl:duration-[0.5s]
    xl:!overflow-y-auto
}
.header_wrapper .header .navbar .list_nav::-webkit-scrollbar {
    @apply
    hidden
}


.header_wrapper .header .navbar .list_nav .button_close {
    @apply
    hidden
    xl:flex
    items-center
    justify-center
    w-[24px]
    h-[24px]
    min-w-[24px]
    border-[1px]
    border-white
    rounded-[8px]
}

.header_wrapper .header .navbar .list_nav.active {
    box-shadow: 0 0 10px 0 #000;
    @apply
    left-0
}

.header_wrapper .header .navbar .list_nav .button_close::before,
.header_wrapper .header .navbar .list_nav .button_close::after {
    content: "";
    @apply
    bg-white
    cursor-pointer
    rounded-[12px]
    w-[2px]
    h-[20px]
    absolute
}

.header_wrapper .header .navbar .list_nav .button_close::before {
    transform: rotate(45deg);
}
.header_wrapper .header .navbar .list_nav .button_close::after {
    transform: rotate(-45deg);
}

.header_wrapper .header .navbar .list_nav:hover .mark {
    @apply
    opacity-100
}
.header_wrapper .header .navbar .list_nav .mark {
    @apply
    w-[15px]
    h-[3px]
    bg-primary-gold
    absolute
    left-0
    top-[100%]
    rounded-[10px]
    opacity-0
    duration-[0.3s]
    xl:hidden
    
}
.header_wrapper .header .navbar .list_nav .mark.active {
    @apply
    opacity-100
}
.header_wrapper .header .navbar ul {
    @apply
    flex
    mr-auto
    ml-auto
    items-center
    justify-start
    relative
    xl:flex-col
    xl:w-full
    xl:items-start
    xl:mb-[20px]
    xl:gap-y-[25px]
}

.header_wrapper .header .navbar ul:hover .mark{
    @apply
    opacity-100
}
.header_wrapper .header .navbar ul li {
    @apply
    mr-[80px]
    /* w-[81px] */
    text-[20px]
    xl:text-[16px]
    font-[400]
    leading-[23.44px]
    xl:mr-0
    xl:mb-[20px]
}

.header_wrapper .header .navbar ul li:last-child {
    @apply
    mr-0
}
.header_wrapper .header .navbar ul li a {
    @apply
    text-white
    flex
    items-center
    justify-start
}

.header_wrapper .header .navbar ul li a .image {
    @apply
    w-[24px]
    h-[24px]
    items-center
    justify-center
    mr-[15px]
    hidden
    xl:flex
}

.header_wrapper .header .navbar ul li a .image svg {
    @apply
    max-w-full
    max-h-full
    object-contain
}

.header_wrapper .header .navbar ul li a .image svg path {
    stroke: #fff;
}
.header_wrapper .header .navbar ul li a.active .image svg path {
    @apply
    stroke-primary-gold
}
.header_wrapper .header .navbar ul li a.active {
    @apply
    text-primary-gold
}

.header_wrapper .header .navbar .button {
    @apply
    flex
    ml-[20px]
    xl:ml-0
    /* mobile:mt-auto */
    xl:w-full
}

.header_wrapper .header .navbar .button a {
    box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.6), 11px 11px 35px rgba(255, 203, 0, 0.2);
    @apply
    w-full
    py-[10px]
    px-[20px]
    whitespace-nowrap
    bg-primary-gold
    text-text-dark
    text-[18px]
    font-[700]
    leading-[21.09px]
    flex
    items-center
    justify-center
    h-[41px]
    pt-[12px]
    rounded-[10px]
}

@media screen and (max-width: 1279px) {
    .header_wrapper .header .navbar .list_nav{
        background: linear-gradient(180deg, #2D3A47 0%, rgba(45, 58, 71, 0) 100%);
        filter: drop-shadow(6px 6px 15px rgba(0, 0, 0, 0.6));
        backdrop-filter: blur(60px);
        @apply
        border-white
        rounded-[12px]
        overflow-hidden
        border-[1px]
    }
}