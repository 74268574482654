.register_layout {
  @apply
  w-[100%]
  min-h-[100vh]
  flex
  items-start
  justify-center
  overflow-hidden
  xl:items-center
  xl:justify-start
  relative
  pt-[50px]
  px-[150px]
  z-10
  xl:px-[100px]
  sm:px-[40px]
  xs:px-[20px]
  xs:mb-[100px]
}

.register_layout .register_wrapper {
  @apply
  w-[100%]
  max-w-[1649px]
  xl:max-w-[none]
  mobile:max-w-[none]
  md:max-w-[none]
  sm:max-w-[none]
}

.register_wrapper::-webkit-scrollbar {
  display: none;
}

.register_wrapper .register {
  @apply
  w-[100%]
  flex
}

.register_wrapper .register .container {
  @apply
  flex
  w-full
  items-start
  justify-center
  flex-row
  /* xl:flex-col-reverse
  xl:items-center
  xl:justify-start */
  mobile:flex-col-reverse
  mobile:items-center
  mobile:justify-start
  /* md:flex-col-reverse
  md:items-center
  md:justify-start
  sm:flex-col-reverse
  sm:items-center
  sm:justify-start */
}

.register_wrapper .register .container .items_left {
  @apply
  flex-col
  items-center
  justify-center
  /* max-w-[865px] */
}

.register_wrapper .register .container .items_left .title {
  @apply
  flex
  items-end
  justify-start
  mb-[14px]
}

.register_wrapper .register .container .items_left .title .logo {
  @apply
  w-[5vw]
  h-[auto]
  /* xl:w-[70px] */
  /* mobile:w-[70px] */
  /* md:w-[60px] */
  /* sm:w-[50px] */
  flex
  mr-[19px]
}

.register_wrapper .register .container .items_left .title .logo img {
  @apply
  max-w-[100%]
  max-h-[100%]
  object-contain
}

.register_wrapper .register .container .items_left .title .text {
  @apply
  text-[#ffffff]
  
}

.register_wrapper .register .container .items_left .title .text > h1 {
  @apply
  font-[700]
  text-[5vw]
  whitespace-nowrap
  leading-[3.8vw]
  /* xl:text-[60px]
  mobile:text-[60px]
  md:text-[50px]
  sm:text-[40px] */
}

.register_wrapper .register .container .items_left .explain {
  @apply
  mb-[27px]
}
.register_wrapper .register .container .items_left .explain p {
  @apply
  text-[1.9vw]
  font-[400]
  text-[#ffffff]
  xl:text-[26px]
  mobile:text-[26px]
  md:text-[24px]
  md:leading-[31.48px]
  sm:text-[24px]
  sm:leading-[31.48px]
}
.register_wrapper .register .container .items_left .bottom_explain {
  @apply
  mb-[20px]
  text-[24px]
}
.register_wrapper .register .container .items_left .image {
  @apply
  mb-[69px]
  w-[100%]
  max-w-[358px]
  xs:max-w-[100%]
  /* xl:max-w-[250px]
  xl:mb-[30px]
  mobile:max-w-[250px]
  mobile:mb-[30px]
  md:max-w-[150px]
  md:mb-[20px] */
}
.register_wrapper .register .container .items_left .image img {
  @apply
  max-w-full
  max-h-full
  object-contain
}
.register_wrapper .register .container .items_left .register_wrapper{
  @apply
  flex
  flex-col
  md:items-start
  justify-start
  mb-[138px]
  sm:items-center
}

.register_wrapper .register .container .items_left .register_wrapper .title {
  @apply
  text-primary-gold
  mb-[31px]
  font-[600]
  text-[36px]
  /* text-center */
}

.register_wrapper .register .container .items_left .register_wrapper .button_show_register {
  @apply
  w-[100%]
  flex
  justify-start
  items-center
  mt-[10px]
}
.register_wrapper .register .container .items_left .register_wrapper form {
  @apply
  w-[100%]
  max-w-[529px]
  flex
  flex-wrap
  justify-between
  relative
  gap-y-[20px]
  z-[2]
}
.register_wrapper .register .container .items_left .register_wrapper .button_show_register .button_back {
  @apply
  w-[48px]
  min-w-[48px]
  h-[48px]
  mr-[15px]
  border-[1.5px]
  rounded-[7px]
  border-[#FFEA00]
  flex
  items-center
  justify-center
}
.register_wrapper .register .container .items_left .register_wrapper .button_show_register .button_back .image {
  @apply
  w-[24px]
  h-[24px]
  flex
  items-center
  justify-center
  mb-0
}

.register_wrapper .register .container .items_left .register_wrapper .button_show_register .button_back .image svg {
  @apply
  max-w-full
  max-h-full
  object-contain
}
.register_wrapper .register .container .items_left .register_wrapper .button_show_register .button {
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.6), 11px 11px 35px rgba(255, 234, 0, 0.15);
  @apply
  font-[inherit]
  w-[100%]
  cursor-pointer
  px-[10px]
  py-[5px]
  h-[48px]
  rounded-[10px]
  bg-primary-gold
  text-[#1D201F]
  text-[20px]
  font-[700]
  outline-none
  flex
  items-center
  justify-center
}
.register_wrapper .register .container .items_left .register_wrapper .button_show_register .button .image {
  animation-name: sending;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @apply
  w-[24px]
  h-[24px]
  min-w-[24px]
  flex
  items-center
  justify-center
  mb-0
  mr-[10px]
}

@keyframes sending {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.register_wrapper .register .container .items_left .register_wrapper .button_show_register .button .image img {
  @apply
  max-w-full
  max-h-full
  object-contain
}
.register_wrapper .register .container .items_left .register_wrapper .button_show_register .button:disabled {
  @apply
  opacity-80
  cursor-not-allowed
}

.register_wrapper .register .container .items_right {
  @apply
  flex
  items-center
  justify-center
}

.register_wrapper .register .container .items_right .image {
  @apply
  w-[50vw]
  flex
  items-center
  justify-center
  relative
  xs:w-[100vw]
  xs:mb-[40px]
  /* xl:w-[700px]
  mobile:w-[700px]
  md:w-[500px]
  sm:w-[400px] */
  /* xl:mb-[20px]
  mobile:mb-[20px]
  md:mb-[10px]
  sm:mb-[10px] */
}

/* .register_wrapper .register .container .items_right .image::before {
  content: "";
  background: radial-gradient(39% 39% at 50% 50%, rgba(240, 160, 77, 0.4) 0%, rgba(29, 32, 31, 0) 83.65%);
  @apply
  absolute
  w-[2218px]
  h-[2218px]
  z-[-1]
  top-[-50%]
  left-[-50%]
} */

.register_wrapper .register .container .items_right .image img {
  @apply
  max-w-[100%]
  max-h-[100%]
  object-contain
}

@media screen and (max-width: 640px) {
  .register_wrapper {
      @apply
      mb-[100px]
  }
  .register_wrapper .register .container .items_right .image{
      @apply
      mb-[10px]
      w-[100%]
      max-w-[400px]
  }
  .register_wrapper .register {
      @apply
      max-w-[none]
  }
  .register_wrapper .register .container {
      @apply
      flex-col-reverse
      items-center
      justify-start
  }

  .register_wrapper .register .container .items_left .items {
      @apply
      flex-col
      items-center
  }

  .register_wrapper .register .container .items_left .register_wrapper form {
      @apply
      flex-col
  }

  .register_wrapper .register .container .items_left {
      @apply
      mr-0
      w-[auto]
  }
  .register_wrapper .register .container .items_left .title .logo {
      @apply
      w-[57px]
  }
  .register_wrapper .register .container .items_left .title .text > h1 {
      @apply
      text-[40px]
  }
  .register_wrapper .register .container .items_left .explain p {
      @apply
      leading-[31.48px]
      text-[24px]
  }
  .register_wrapper .register .container .items_left .image {
      @apply
      md:mb-[20px]
      /* max-w-[150px] */
  }
}

.error {
  @apply text-[12px]
    ml-[5px]
    text-[#FA0000];
}

/* input */
.input_layout {
  @apply
  w-full
  flex
  items-start
  justify-center
  gap-x-[20px]
  sm:flex-col
  sm:gap-y-[20px]
}
.input_wrapper {
  @apply w-[100%] max-w-[251px] mr-[27px] flex items-start justify-start
}

.input_wrapper:nth-child(even) {
  @apply mr-0
}

.input_wrapper label {
  @apply w-[100%] flex flex-col items-start justify-start
}

.input_wrapper label span {
  @apply w-[100%] flex items-center justify-start pl-[5px] text-[16px] text-[#ffffff] font-[400] mb-[10px] leading-[18.75px]
}
.input_wrapper label .error {
  @apply
  text-[12px]
  ml-[5px]
  text-[#FA0000]
}
.input_wrapper label .input {
  transition: .3s;
  background: linear-gradient(92.17deg, #FFFFFF 56.58%, #F0A04D 89.96%);

  @apply w-[100%] flex justify-start items-center relative rounded-[5px] z-[1]
}

.input_wrapper label .input::before {
  content: "";
  background: linear-gradient(135deg, #2D3A47 40%, rgba(45, 58, 71, 0.6) 100%);
  filter: drop-shadow(0px 1px 7px rgba(21, 21, 95, .1));
  backdrop-filter: blur(60.5px);
  width: calc(100% - 2px);
  height: calc(100% - 2.5px);
  left: 1px;
  @apply absolute rounded-[5px] -z-[1]
}

.input_wrapper label .input.active {
  background: #FFCB00;
}
.input_wrapper label .input.error {
  background: #FA0000;
}
.input_wrapper label .input input {
  font-family: inherit;
  @apply w-[100%] p-[10px] h-[35px] bg-transparent outline-none text-[#ffffff] text-[12px] font-[300]
}
.input_wrapper label .input select {
  font-family: inherit;
  @apply w-[100%] p-[10px] h-[35px] bg-transparent outline-none text-[#ffffff] text-[12px] font-[300]
}

.input_wrapper label .input select option {
  @apply
  text-p-dark-1
  my-[4px]
}

.input_wrapper label .input input::-webkit-inner-spin-button {
  display: none;
}

@media screen and (max-width: 640px) {
  .input_wrapper{
      @apply
      max-w-[none]
      mr-[0]
  }
}

.checkbox_layout {
  @apply
  w-full
  flex
  items-start
  justify-start
  flex-col
  mb-5
}
.checkbox_layout span.error {
  @apply
  text-[12px]
  ml-[5px]
  text-[#FA0000]
}
.checkbox_layout .checkbox_wrapper {
  @apply
  flex
  items-start
  justify-start
  relative
}
.checkbox_layout .checkbox_wrapper > input {
  @apply
  absolute
  left-0
  top-0
  opacity-0
  z-[1]
  cursor-pointer
  w-full
  h-full
}
.checkbox_layout .checkbox_wrapper .checkbox {
  @apply
  w-[23px]
  min-w-[23px]
  h-[23px]
  rounded-md
  mr-4
  flex
  items-center
  justify-center
  mt-[-4px]
  border-white
  border-[1px]
  relative
}
.checkbox_layout .checkbox_wrapper .checkbox::before {
  background: url('./../../pic/icon/tick.svg') round;
  background-size: cover;
  content: "";
  @apply
  absolute
  w-[14px]
  h-[11px]
  opacity-0
  duration-100
  bg-no-repeat
}

.checkbox_layout .checkbox_wrapper > input:checked ~ .checkbox::before {
  @apply
  opacity-100
}

.checkbox_layout .checkbox_wrapper .text {
  @apply
  flex
}

.checkbox_layout .checkbox_wrapper .text p {
  @apply
  font-medium
  text-white
  text-xs
}

.checkbox_layout .checkbox_wrapper .text p a {
  @apply
  text-light-blue
  relative
  z-10
  ml-[4px]
}