.footer_wrapper {
    @apply
    w-full
    flex
    items-center
    flex-col
    justify-start
    px-[70px]
    /* sm:px-[70px] */
    xs:px-[16px]
    relative
    z-[1]
    mt-auto
}

.footer_wrapper .footer {
    border-color: #ffffff3a;
    @apply
    w-full
    max-w-[1583px]
    border-t-[2.5px]
    border-solid
    flex
    items-start
    justify-between
    mobile:flex-col
    mobile:items-center
    mobile:justify-start
}

.footer_wrapper .footer .items_left {
    @apply
    w-full
    py-[50px]
    max-w-[785px]
    flex
    flex-col
    items-start
    justify-start
    mr-[20px]
    mobile:max-w-none
    mobile:mr-0
    xs:pb-[25px]
}

.footer_wrapper .footer .items_left .logo {
    @apply
    flex
    items-center
    justify-start
    mb-[24px]
}

.footer_wrapper .footer .items_left .logo .image {
    @apply
    w-[58px]
    h-[58px]
    mr-[15px]
    flex
    items-center
    justify-center
    sm:w-[30px]
}
.footer_wrapper .footer .items_left .logo .image img {
    @apply
    max-w-full
    max-h-full
    object-contain
}
.footer_wrapper .footer .items_left .logo .text {
    @apply
    w-[83px]
    flex
    items-center
    justify-center
    sm:w-[43px]

}
.footer_wrapper .footer .items_left .logo .text img {
    @apply
    max-w-full
    max-h-full
    object-contain
}

.footer_wrapper .footer .items_left .explain {
    @apply
    text-white
    text-[20px]
    font-[400]
    leading-[37px]
    text-justify
    xs:text-[16px]
    xs:leading-[24px]
}

.footer_wrapper .footer .items_left .link {
    @apply
    w-full
    flex
    flex-col
    items-start
    justify-center
    mt-[20px]
}

.footer_wrapper .footer .items_left .link a {
    @apply
    text-[20px]
    font-[700]
    text-light-blue
}

.footer_wrapper .footer .items_right {
    @apply
    w-full
    max-w-[500px]
    pt-[59px]
    pb-[42px]
    flex
    flex-col
    items-end
    justify-start
    mobile:max-w-none
    mobile:items-start
    xs:py-[25px]
}

.footer_wrapper .footer .items_right .social_media {
    @apply
    flex
    flex-col
    items-end
    justify-start
    mb-[48px]
}

.footer_wrapper .footer .items_right .social_media .title {
    @apply
    text-[20px]
    text-white
    leading-[37px]
    font-[400]
    mb-[23px]
}

.footer_wrapper .footer .items_right .social_media .items {
    @apply
    w-full
    flex
    items-center
    justify-end
    mobile:justify-start
}

.footer_wrapper .footer .items_right .social_media .items .item {
    @apply
    w-[30px]
    min-w-[30px]
    h-[30px]
    flex
    items-center
    justify-center
    mr-[30px]
    /* cursor-pointer */
}
.footer_wrapper .footer .items_right .social_media .items .item img {
    @apply
    max-w-full
    max-h-full
    object-contain
}
.footer_wrapper .footer .items_right .social_media .items .item:last-child {
    @apply
    mr-0
}

.footer_wrapper .footer .items_right .footer_explain p {
    @apply
    text-primary-gold
    text-[20px]
    font-[700]
}
.footer_wrapper .footer .items_right .footer_explain a{
    @apply
    text-light-blue
}