.landing_page_wrapper {
    background: #171936;
    @apply
    w-full
    min-h-screen
    flex
    text-white
    flex-col
    items-center
    justify-start
    relative
    z-[1]
    overflow-x-hidden
}
.landing_page_wrapper::before {
    background: url("./../pic/home/bg-noise.png");
    background-repeat: repeat;
    content: "";
    @apply
    w-full
    h-full
    absolute
    left-0
    top-0
}